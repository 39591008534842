import Image from "@components/Image";
import { ImageProps } from "next/image";

export function ContentfulImage(
  props: { image: any } & Omit<ImageProps, "src"> & {
    height?: number;
    width?: number;
  }
) {
  const { image, height, width, ...rest } = props;

  if (!image?.fields?.file) return null;

  const ext = image.fields.file.contentType.split("/")[1];
  const imageUrl = "/images/contentful/" + image.sys.id + "." + ext;

  // Check if the 'fill' prop is present
  const hasFillProp = rest.hasOwnProperty('fill') && rest.fill;

  // Conditionally set height and width
  const imageDimensions = !hasFillProp
    ? {
      height:
        height ||
        image.fields.details?.image.height ||
        image.fields?.file.details.image?.height ||
        1,
      width:
        width ||
        image.fields.details?.image.width ||
        image.fields?.file.details.image?.width ||
        1,
    }
    : {};

  return (
    <Image
      src={imageUrl}
      className="w-full"
      style={{
        objectFit: "contain",
      }}
      {...imageDimensions}
      {...rest}
    />
  );
}
